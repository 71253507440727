export const menuItems = [
  {
    path: "/ja",
    text: "ホーム",
  },
  {
    path: "/ja/services",
    text: "サービス",
  },
  {
    path: "/ja/blogs",
    text: "ブログ",
  },
  {
    path: "/ja/contact",
    text: "問い合わせ",
  },
  {
    path: "/",
    text: "English",
  },
]
